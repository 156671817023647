import { Dialog, Notify, Loading, setCssVar, Quasar } from "quasar";
import "quasar/dist/quasar.sass";
import "shared-styles/index.scss";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

import(`quasar/lang/pl`).then((lang) => Quasar.lang.set(lang.default));

iconSet.table.arrowUp = "fas fa-caret-up";

setCssVar("primary", "#FFAB00");
setCssVar("dark-200", "#8b8bc1");
setCssVar("secondary", "#dbeaff");
setCssVar("dark", "#2D2D53");
setCssVar("positive", "#34800E");
setCssVar("negative", "#CC0C36");
setCssVar("info", "#295CE1");
setCssVar("warning", "#E85F29");
setCssVar("accent", "#D0D4DA"); // neutral from scss variables

Notify.registerType("custom-positive", {
  color: "positive",
  textColor: "white",
  classes: "noti__custom--positive",
  avatar: require("@/assets/positive-notification.svg"),
  badgeColor: "dark-700",
  badgeClass: "default-noti-badge",
});
Notify.registerType("custom-warning", {
  color: "warning",
  textColor: "white",
  classes: "noti__custom--warning",
  avatar: require("@/assets/warning-notification.svg"),
});
Notify.registerType("custom-negative", {
  textColor: "white",
  color: "negative",
  classes: "noti__custom--negative",
  avatar: require("@/assets/negative-notification.svg"),
});
Notify.registerType("custom-info", {
  textColor: "white",
  color: "info",
  classes: "noti__custom--info",
  avatar: require("@/assets/info-notification.svg"),
});
Notify.registerType("custom-help", {
  classes: "noti__custom--help",
  color: "white",
  avatar: require("@/assets/help-notification.svg"),
});

export default {
  plugins: {
    Dialog,
    Notify,
    Loading,
  },
  iconSet,
};
