import { defineStore } from "pinia";
import api from "../api/api";

export const useGlobalStore = defineStore("globalStore", () => {
  const confirmEmail = async (confirmationToken: string) => {
    try {
      await api.confirmEmail(confirmationToken);
    } catch (error: any) {
      throw new Error("error");
    }
  };
  return {
    confirmEmail,
  };
});
