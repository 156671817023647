import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { decamelizeKeys, camelizeKeys } from 'humps';

const transformData = (data: unknown) => (data instanceof FormData ? data : decamelizeKeys(data));

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    });

    http.interceptors.response.use(
      (response) => ({ ...response, data: camelizeKeys(response.data) }),
      (error) => Promise.reject(error)
    );

    http.interceptors.request.use(
      (config) => {
        const requestsWithParams = ['get', 'delete'];
        if (requestsWithParams.includes(config.method ?? '')) {
          return { ...config, params: decamelizeKeys(config.params) };
        }
        return config;
      },
      (error) => Promise.reject(error.response)
    );

    this.instance = http;
    return http;
  }

  get<Type = unknown, Response = AxiosResponse<Type>>(
    url: string,
    requestConfig: {
      config?: AxiosRequestConfig;
    }
  ): Promise<Response> {
    const { config } = requestConfig;
    return this.http.get<Type, Response>(url, config);
  }

  post<Type = unknown, Response = AxiosResponse<Type>>(
    url: string,
    requestConfig: {
      data?: Type;
      config?: AxiosRequestConfig;
    }
  ): Promise<Response> {
    const { config, data } = requestConfig;
    return this.http.post<Type, Response>(url, transformData(data), config);
  }

  patch<Type = unknown, Response = AxiosResponse<Type>>(
    url: string,
    requestConfig: {
      data?: Type;
      config?: AxiosRequestConfig;
    }
  ): Promise<Response> {
    const { data, config } = requestConfig;
    return this.http.patch<Type, Response>(url, transformData(data), config);
  }

  put<Type = unknown, Response = AxiosResponse<Type>>(
    url: string,
    requestConfig: {
      data?: Type;
      config?: AxiosRequestConfig;
    }
  ): Promise<Response> {
    const { data, config } = requestConfig;
    return this.http.put<Type, Response>(url, transformData(data), config);
  }

  delete<Type = unknown, Response = AxiosResponse<Type>>(
    url: string,
    requestConfig: {
      data?: Type;
      config?: AxiosRequestConfig;
    }
  ): Promise<Response> {
    const { config } = requestConfig;
    return this.http.delete<Type, Response>(url, config);
  }
}

export default new Http();
