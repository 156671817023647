import type { AxiosResponse } from "axios";
import api from "./base";
import type {
  UserInfo,
  CompanyInfo,
  RegisterData,
  UserActivationData,
  UserAuthenticationData,
  UserPhoneNumberVerifyData,
} from "./api.types";

export default {
  logOut: async (): Promise<AxiosResponse> => api.delete("/users/sign_out", { data: {} }),
  userLogIn: async (login: string, password: string): Promise<AxiosResponse> =>
    api.post("/users/sign_in", { data: { user: { login, password } } }),
  getActivateData: async (confirmationToken: string): Promise<AxiosResponse<UserInfo>> =>
    api.get("api/v1/users/confirmation", { config: { params: { confirmation_token: confirmationToken } } }),
  getRegisterCompanyInfo: async (registrationLink: string): Promise<AxiosResponse<CompanyInfo>> =>
    api.get(`/employees/api/v1/registrations/${registrationLink}`, { config: {} }),
  registerUser: async (data: RegisterData): Promise<AxiosResponse> =>
    api.post("/employees/api/v1/registrations", { data }),
  userActivate: async (user: UserActivationData): Promise<AxiosResponse> => api.patch("/confirm", { data: user }),
  userAuthenticate: async (token: UserAuthenticationData): Promise<AxiosResponse> =>
    api.get("/users/confirmation", { config: { params: { confirmationToken: token.confirmationToken } } }),
  generateOtgCode: (phone: string) =>
    api.patch("employees/api/v2/settings/generate_otg_code", { data: { user: { phone_number: phone } } }),
  updateUserEmail: (email: string) =>
    api.patch("employees/api/v2/settings/update_email_from_terminal", { data: { user: { email } } }),
  updateUserPhoneNumber: (data: UserPhoneNumberVerifyData) =>
    api.patch("employees/api/v2/settings/update_phone_number_from_terminal", { data }),
  requestPasswordReset: async (login: string): Promise<AxiosResponse> =>
    api.post("/users/password", { data: { phoneOrEmail: login } }),
  updatePassword: async (password: string, passwordRepeat: string, passwordToken: string): Promise<AxiosResponse> =>
    api.patch("/users/password", {
      data: { user: { password, password_confirmation: passwordRepeat, reset_password_token: passwordToken } },
    }),
  confirmEmail: async (token: string): Promise<AxiosResponse<CompanyInfo>> =>
    api.get(`/users/confirmation?confirmation_token=${token}`, { config: {} }),
};
