import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import throwError from "../utils/helpers/throwError";
import { useActivateStore } from "../views/activate/store/activateStore";
import { useGlobalStore } from "../store/globalStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/login/Login.view.vue"),
  },
  {
    path: "/activate",
    name: "activate",
    component: () => import(/* webpackChunkName: "Activate" */ "../views/activate/Activate.view.vue"),
    async beforeEnter(to, from, next) {
      const store = useActivateStore();
      const redundantRedirect = to.fullPath === "/activate?expired=true";

      if (redundantRedirect) {
        next();
        return;
      }

      try {
        await store.getActivateData(to.query.confirmation_token as string);
        next();
      } catch (error) {
        next({
          name: "activate",
          query: {
            expired: "true",
          },
        });
        throwError(error);
      }
    },
  },

  {
    path: "/register/:registrationLink",
    name: "register",
    component: () => import(/* webpackChunkName: "Register" */ "../views/register/Register.view.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    children: [
      {
        path: "send",
        name: "send-link",
        component: () =>
          import(/* webpackChunkName: "SendLink" */ "../views/reset/subviews/send-link/SendLink.view.vue"),
      },
      {
        path: "password",
        name: "change-password",
        component: () =>
          import(
            /* webpackChunkName: "ChangePassword" */ "../views/reset/subviews/change-password/ChangePassword.view.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, _, next) => {
  const routeExists = router.options.routes.some(
    (route) => route.name === to.name || route.children?.some((child) => child.name === to.name),
  );
  // Legacy routes validation
  // /users/password/edit?reset_password_token
  // /users/confirmation?confirmation_token

  if (to.path.includes("/users/password/edit")) {
    next({
      name: "change-password",
      query: {
        reset_password_token: to.query.reset_password_token,
      },
    });
  } else if (to.path.includes("/confirm_email")) {
    const store = useGlobalStore();
    store.confirmEmail(to.query.confirmation_token as string);
    next("/");
  } else if (to.path.includes("/users/confirmation")) {
    next({
      name: "activate",
      query: {
        confirmation_token: to.query.confirmation_token,
      },
    });
  } else if (routeExists) {
    next();
  } else {
    next("/");
  }
});

export default router;
