export const openHubSpotConversations = () => {
  const hubSpotHolder = document.getElementById('hub-spot-chat-holder');

  if (hubSpotHolder && window.HubSpotConversations) {
    hubSpotHolder.style.visibility = 'visible';
    window.HubSpotConversations.widget.open();
  }
};

export const closeHubSpotConversations = () => {
  const hubSpotHolder = document.getElementById('hub-spot-chat-holder');

  if (hubSpotHolder && window.HubSpotConversations) {
    hubSpotHolder.style.visibility = 'hidden';
  }
};
