import { createApp, computed, ref } from 'vue';
import { createPinia } from 'pinia';
import { Quasar, Screen } from 'quasar';
import Rollbar, { LogArgument } from 'rollbar';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import quasarUserOptions from './quasar-user-options';
import resetStore from './store/helpers/resetStore';
import 'floating-vue/dist/style.css';
import './utils/validations/validations';

const pinia = createPinia();
pinia.use(resetStore);

const rollbarInstance = ref(
  new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_PUSH_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
  })
);
const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
  rollbarInstance.value.error(err as LogArgument);
  throw err; // rethrow
};
app
  .provide(
    'isMobile',
    computed(() => Screen.lt.md)
  )
  .provide(
    'isDesktop',
    computed(() => Screen.gt.md)
  )
  .use(Quasar, quasarUserOptions)
  .use(i18n)
  .use(pinia)
  .use(router);

Screen.setSizes({ sm: 320, md: 768, lg: 1536, xl: 1920 });

router.isReady().then(() => {
  app.mount('#app-login');
});

if ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/packs/login/service-worker.js')
      .then((registration) => {
        console.log('Service Worker zarejestrowany pomyślnie z zakresem: ', registration.scope);
      })
      .catch((err) => {
        console.error('Rejestracja Service Workera nie powiodła się: ', err);
      });
  });
}
