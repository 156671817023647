// eslint-disable-next-line camelcase
import { required, email } from "@vee-validate/rules";
import { defineRule } from "vee-validate";
import i18n from "../../i18n";

const { t }: any = i18n.global;

defineRule(
  "isRequired",
  (value: unknown) => required(value) || t("validations.required")
);
defineRule(
  "isEmail",
  (value: string) => email(value) || t("validations.invalidEmail")
);
defineRule("minLength", (value: string, [length]: [number]) =>
  value
    ? value.length >= length || t("validations.minLength", { length })
    : null
);
defineRule("hasNumber", (value: string) =>
  value ? /\d/.test(value) || t("validations.hasNumber") : null
);
defineRule("hasCapitalLetter", (value: string) =>
  value ? /[A-Z]/.test(value) || t("validations.hasCapitalLetter") : null
);
defineRule("hasSmallLetter", (value: string) =>
  value ? /[a-z]/.test(value) || t("validations.hasSmallLetter") : null
);
defineRule("matchValues", (value: string, [secondValue]: [string]) => {
  return value === secondValue || t("validations.matchValues");
});
defineRule("exactLength", (value: string, [length]: [string]) =>
  value ? value.length === +length || t("validations.length", { length }) : null
);
defineRule(
  "isPhoneNumber",
  (value: string) =>
    /^\+\d{10}(\d{1,2})?$/.test(value) || t("validations.invalidNumber")
);
defineRule(
  "isEmailOrPhoneNumber",
  (value: string) =>
    email(value) ||
    /^\+?\d{1,3}?[-.\s]?\d{1,3}[-.\s]?\d{3,4}[-.\s]?\d{4}$/.test(value) ||
    t("validations.emailOrPhone")
);
defineRule("emailDomainValidation", (email: string, domainArray: string[]) => {
  const [username, domain] = email.split("@");

  if (domainArray.includes(domain)) return true;

  return t("validations.emailDomain");
});
defineRule("onlyNumber", (value: string) =>
  value ? /^[0-9]*$/.test(value) || t("validations.onlyNumbers") : null
);
